<template>
  <div class="header">
    <div class="left">
      <router-link replace to="/photo" class="title">{{ $t('lang.photos') }}</router-link>
      <router-link replace to="/album" class="title">{{ $t('lang.gallery') }}</router-link>
    </div>
    <div class="right">
      <span class="upload" @click="toLogin" v-if="!$store.state.userInfo">{{ $t('lang.upload') }}</span>
      <van-uploader :after-read="afterRead" multiple v-else>
        <span class="upload">{{ $t('lang.upload') }}</span>
      </van-uploader>
    </div>
    <van-popup v-model="show" round position="bottom">
      <div style="padding: 20px;">
        <div class="select">
          <div>
            <span>共{{photoLength}}张&nbsp;{{photoSize}}KB</span>
            <span>上传成功后，需要支付上链费</span>
          </div>
          <img :src="photoSrc" alt="">
        </div>
        <div class="select">
          <div>
            <span>图片质量</span>
            <span>质量越高图片越清晰</span>
          </div>
        </div>
        <div class="select">
          <div>
            <span>谁可以看</span>
            <span v-if="true">你的图片将以加密方式保存在区块链上，其他人无法查看</span>
            <span v-if="false">区块链数据不能删除，图片一旦公开发布，则可能永远公开</span>
          </div>
        </div>
        <div>
          <van-button type="info">确认上传</van-button>
        </div>
      </div>
    </van-popup>
    <van-overlay :show="isLoading">
      <van-loading class="loading" type="spinner" color="#E5E5E5" vertical>{{ $t('lang.onTheChain') }}</van-loading>
    </van-overlay>
  </div>
</template>

<script>
import { Uploader, Popup, Loading, Overlay } from 'vant'
import common from '@/utils/common'
import { fileToMetaFile, setAttachments } from 'sdk'
export default {
  name: "Header",
  data() {
    return {
      show: false,
      attachments: [],
      photoName: "默认相册",
      blobSize:0,
      encrypt: 0,
      photoLength: 0,
      photoSize: 0,
      photoSrc: '',
      isLoading: false
    }
  },
  components: {
    [Uploader.name]: Uploader,
    [Popup.name]: Popup,
    [Loading.name]: Loading,
    [Overlay.name]: Overlay
  },
  computed: {
    txId() {
      return localStorage.getItem('showId')
    }
  },
  methods: {
    toLogin() {
      this.$router.push({
        name: 'User2',
        params: {
          txId
        }
      })
    },
    addSimplePhotoAlbumProtocol(files, needConfirm, checkOnly, amount) {
      return new Promise(async(resolve, reject) => {
        let params = {
          "name": this.photoName,
          "description": '', //随机标识码，做过滤使用
          "parentNode": '',
          'encrypt': this.encrypt,
          "photos": JSON.parse(JSON.stringify(files)),
        }

        const { data, attachments} = await setAttachments(params,[
          { name: 'photos', encrypt: '0'}
        ])

        const txData = common.getTxData({
          nodeName: 'SimplePhotoAlbum',
          brfcId: 'edc6cff36400',
          path: '/Protocols/SimplePhotoAlbum',
          data: JSON.stringify(data),
          attachments: attachments,
          needConfirm,
          checkOnly,
          payTo: amount ? [
            {
              amount,
              address: process.env.VUE_APP_PlatformAddress
            }
          ] : []
        })

        this.$store.state.sdk.sendMetaDataTx(txData).then(res=>{
          resolve(res)
        }, (err) => {
          reject()
        })
      })
    },
    async afterRead(file) {
      this.isLoading = true
      let files = file instanceof Array ? file : [file]
      let filesTemp = []
      for (let i =0; i< files.length; i++) {
        const file = await fileToMetaFile(files[i].file)
        filesTemp.push(file)
      }

      // this.show = true

      try {
        let photoAlbumAmountRes = await this.addSimplePhotoAlbumProtocol(filesTemp, false, true)
        this.isLoading = false
        if ((photoAlbumAmountRes && photoAlbumAmountRes.code === 200) || (photoAlbumAmountRes && photoAlbumAmountRes.code === 205)) {
          if (photoAlbumAmountRes.data.usedAmount) {
            const amountRes = Math.ceil(parseFloat(photoAlbumAmountRes.data.usedAmount) * 0.05)
            let res = await this.addSimplePhotoAlbumProtocol(filesTemp, true, false, amountRes)
            if (res.code === 200) {
              this.$toast(this.$t('lang.success'))
              this.$router.back()
            }
          }
        }
      } catch (err) {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .header {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  .left {
    .title {
      font-size: 20px;
      color: #BFC2CC;
      &.router-link-exact-active {
        color: #303133;
      }
      &:first-child {
        margin-right: 12px;
      }
    }
  }
  .right {
    width: 96px;
    height: 30px;
    background-color: #617FFF;
    border-radius: 6px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    .upload {
      color: #FFFFFF;
      font-size: 12px;
    }
  }
    .select {
      display: flex;
      align-items: center;
      height: 60px;
      div{
        display: flex;
        flex-direction: column;
      }
    }
}
/deep/.van-loading {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
/deep/.van-overlay {
  z-index: 2;
}
</style>
