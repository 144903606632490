var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header" },
    [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c(
            "router-link",
            { staticClass: "title", attrs: { replace: "", to: "/photo" } },
            [_vm._v(_vm._s(_vm.$t("lang.photos")))]
          ),
          _c(
            "router-link",
            { staticClass: "title", attrs: { replace: "", to: "/album" } },
            [_vm._v(_vm._s(_vm.$t("lang.gallery")))]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          !_vm.$store.state.userInfo
            ? _c(
                "span",
                { staticClass: "upload", on: { click: _vm.toLogin } },
                [_vm._v(_vm._s(_vm.$t("lang.upload")))]
              )
            : _c(
                "van-uploader",
                { attrs: { "after-read": _vm.afterRead, multiple: "" } },
                [
                  _c("span", { staticClass: "upload" }, [
                    _vm._v(_vm._s(_vm.$t("lang.upload"))),
                  ]),
                ]
              ),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { round: "", position: "bottom" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("div", { staticStyle: { padding: "20px" } }, [
            _c("div", { staticClass: "select" }, [
              _c("div", [
                _c("span", [
                  _vm._v(
                    "共" +
                      _vm._s(_vm.photoLength) +
                      "张 " +
                      _vm._s(_vm.photoSize) +
                      "KB"
                  ),
                ]),
                _c("span", [_vm._v("上传成功后，需要支付上链费")]),
              ]),
              _c("img", { attrs: { src: _vm.photoSrc, alt: "" } }),
            ]),
            _c("div", { staticClass: "select" }, [
              _c("div", [
                _c("span", [_vm._v("图片质量")]),
                _c("span", [_vm._v("质量越高图片越清晰")]),
              ]),
            ]),
            _c("div", { staticClass: "select" }, [
              _c("div", [
                _c("span", [_vm._v("谁可以看")]),
                true
                  ? _c("span", [
                      _vm._v(
                        "你的图片将以加密方式保存在区块链上，其他人无法查看"
                      ),
                    ])
                  : _vm._e(),
                false
                  ? _c("span", [
                      _vm._v(
                        "区块链数据不能删除，图片一旦公开发布，则可能永远公开"
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c(
              "div",
              [
                _c("van-button", { attrs: { type: "info" } }, [
                  _vm._v("确认上传"),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "van-overlay",
        { attrs: { show: _vm.isLoading } },
        [
          _c(
            "van-loading",
            {
              staticClass: "loading",
              attrs: { type: "spinner", color: "#E5E5E5", vertical: "" },
            },
            [_vm._v(_vm._s(_vm.$t("lang.onTheChain")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }